<template>
  <div>
    <div class="h-screen md:h-75vh lg:h-screen flex items-center justify-center">
      <div
        class="w-full md:w-1024 py-14 mx-auto flex flex-col md:flex-row items-center md:items-start justify-end"
      >
        <img
          class="w-36 md:w-64"
          src="@/assets/images/icons/Group1608.png"
          alt="Group1608"
        />
        <div class="w-full md:w-8/12 px-4 md:px-0 mt-10">
          <h3 class="text-2xl font-black">
            DynEd Student Certificate Verification
          </h3>
          <p class="w-10/12 py-4">
            Because our students work hard to achieve their certified English skills, DynEd works hard to ensure others don’t take the easy road to certification. You can confirm if the certificate you are holding belongs to a DynEd student that has been certified by our institution by entering the certificate identification code in the space below. <br>
            To verify a neo student’s Statement of Results, visit <a target="_blank" href="https://neo-verify.com/" class="text-primary underline">neo-verify.com.</a><br>
            Enter a certificate ID:
          </p>
          <input
            class="w-8/12 p-2 rounded-sm border border-softGrey"
            type="text"
            v-model="cert_id"
            placeholder=""
          />
          <div class="text-red text-sm">{{verifStatus}}</div>
          <RoundedButtonVue
            v-if="cert_id == ''"
            title="Verify"
            bgColor="bg-grey"
            textColor="text-white"
            width="w-max"
            class="mt-4 cursor-not-allowed"
          />
          <div v-else>
            <RoundedButtonVue
              v-if="!toggleLoader"
              title="Verify"
              bgColor="bg-primary"
              textColor="text-white"
              width="w-max"
              class="mt-4"
              @click.native="getCert"
            />
            <LoaderVue v-else />
          </div>
          <p class="text-sm pt-4">If you were unable to verify the certificate or encountered an error, please contact DynEd’s certification department at <a href="mailto:verify@dyned.com" class="text-primary underline">verify@dyned.com.</a></p>
        </div>
      </div>
    </div>
    <!-- view certificate image -->
    <!-- <basic-modals-vue
      :show="showViewCertificationModal"
      @update:show="showViewCertificationModal = $event"
      class="z-10"
    >
      <CloseIconVue
        :width="20"
        :height="20"
        iconColor="#111"
        class="items-center absolute top-10 md:top-7 right-10 md:right-20 cursor-pointer"
        @click.native="closeModals"
      />
      <div v-if="gettersCert.status == 200" class="mt-16 flex flex-col items-center justify-start">
        <img class="w-full lg:w-4/12" :src="gettersCert.data.data.image" alt="certificate image" />
      </div>
      <div v-if="gettersCert.status == 200" class="w-max mt-8 mx-auto">
        <a
          class="py-3 px-10 bg-softGreen text-white rounded-full text-center font-semibold cursor-pointer"
          :href="gettersCert.data.data.pdf"
          target="_blank"
          download
          >Download PDF</a
        >
      </div>
    </basic-modals-vue> -->
    <!-- view certificate image end -->
  </div>
</template>

<script>
import RoundedButtonVue from "../components/buttons/RoundedButton.vue";
import BasicModalsVue from "../components/modals/BasicModals.vue";
import ProficiencyLevelComponentVue from "../components/proficiencylevel/ProficiencyLevelComponent.vue";
import CertificateIconVue from "../components/svgicons/CertificateIcon.vue";
import CloseIconVue from "../components/svgicons/CloseIcon.vue";
import LoaderVue from "../components/loader/Loader.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    RoundedButtonVue,
    BasicModalsVue,
    CloseIconVue,
    CertificateIconVue,
    ProficiencyLevelComponentVue,
    LoaderVue,
  },
  data() {
    return {
      showCertificationModal: false,
      showCertificateResult: false,
      showViewCertificationModal: false,
      cert_id: "",
      toggleLoader: false,
      verifStatus: "",
      skillProficiency: [
        {
          name: "Listening",
          caption:
            "Measures ability to listen, understand and respond to the language expected at this CEFR level.",
          value: 5,
        },
        {
          name: "Social Interaction",
          caption:
            "Measures ability to participate in English conversations and discussions as expected for the CEFR level.",
          value: 6,
        },
        {
          name: "Reading",
          caption:
            "Measures comprehension and retention of information read as expected for the CEFR level.",
          value: 5,
        },
        {
          name: "Grammar",
          caption:
            "Measures mastery of grammatical structures and propoer usage of language in spoken and written form as expected for the CEFR level.",
          value: 6,
        },
        {
          name: "Vocabulary",
          caption:
            "Measures command of essential function and content words in real life situations both spoken and written for the CEFR Level.",
          value: 8,
        },
      ],
    };
  },
  methods: {
    closeModals() {
      this.showViewCertificationModal = false;
      this.toggleLoader = false;
      this.cert_id = "";
      this.$store.commit("moduleGetCertification/clearState");
    },
    openCertificationModal() {
      this.showViewCertificationModal = true;
    },
    getCert() {
      this.toggleLoader = true;
      this.$store.dispatch("moduleGetCertification/getCertification", {
        cert_id: this.cert_id,
      });
    },
  },
  computed: {
    ...mapGetters("moduleGetCertification", {
      gettersCert: "gettersCert",
    }),
  },
  watch: {
    gettersCert(i) {
      if (i.status == 200) {
        this.openCertificationModal();
        this.toggleLoader = false;
        this.verifStatus = "";
      } else {
        this.toggleLoader = false;
        this.verifStatus = i.message
      }
    },
  },
};
</script>

<style>
</style>